/* eslint-disable jsx-a11y/anchor-is-valid */
import Button, { IButtonProps } from 'lib/ui/Button'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

const BaseButton = ({
  children,
  buttonSize,
  display,
  variant,
  type,
  disabled,
  href,
  form,
  id,
  loading,
  name,
  newTab,
  onClick,
  value,
  iconPlacement,
  padding,
  styles
}: IButtonProps) => {
  const { brandColor } = useCompanyAndInvoiceContext()

  return (
    <Button
      brandColor={brandColor}
      buttonSize={buttonSize}
      display={display}
      variant={variant}
      type={type}
      disabled={disabled}
      href={href}
      form={form}
      id={id}
      loading={loading}
      name={name}
      newTab={newTab}
      onClick={onClick}
      value={value}
      iconPlacement={iconPlacement}
      padding={padding}
      styles={styles}
    >
      {children}
    </Button>
  )
}

export default BaseButton

export type { IButtonProps }
