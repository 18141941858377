import { ReactNode } from 'react'

import { LazyMotion, m } from 'framer-motion'
import { ITransition } from 'utils/types'

const loadFeatures = () => import('utils/animations').then(res => res.default)

interface ILayout extends ITransition {
  children: ReactNode
}
const LayoutTransition = ({ children, transition, variants }: ILayout) => (
  <LazyMotion features={loadFeatures}>
    <m.div
      initial='initial'
      animate='animate'
      variants={variants}
      transition={transition}
    >
      {children}
    </m.div>
  </LazyMotion>
)

export default LayoutTransition
