import { domAnimation } from 'framer-motion'

interface IAnimation {
  duration?: number
  transition?: 'bounce' | 'slide'
  x: number
  y: number
}

const setTransition = (transition, duration = 0.5) => {
  switch (transition) {
    case 'bounce':
      return {
        type: 'spring',
        duration,
        bounce: 0.3,
        delay: 1.5
      }

    default:
      return {
        type: 'tween',
        duration,
        delayChildren: 0.5,
        staggerChildren: 0.25
      }
  }
}

export const animateIn = ({ duration, transition, x, y }: IAnimation) => ({
  variants: {
    initial: { opacity: 0, x, y },
    animate: { opacity: 1, x: 0, y: 0 }
  },
  transition: setTransition(transition, duration)
})

export const animateOut = ({ duration, transition, x, y }: IAnimation) => ({
  variants: {
    initial: { opacity: 0, x: 0, y: 0 },
    animate: { opacity: 1, x, y }
  },
  transition: setTransition(transition, duration)
})

export default domAnimation
