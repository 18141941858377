import { defaults } from 'lib/layouts/DefaultLayout'
import { BoxStyles } from 'lib/ui/StepsMeter/StepsMeter.types'
import { createStyles } from 'utils/css'
import theme from 'utils/theme'
export const content = {
  [theme.mediaQuery(defaults.breakpoint, 'max-width')]: {
    paddingTop: 0
  }
}
export const sideBottom = () =>
  createStyles(({ mediaQuery, size, spacing }) => ({
    display: 'none',
    [mediaQuery(defaults.breakpoint)]: {
      marginTop: spacing(1),
      maxWidth: size(17),
      display: 'block'
    }
  }))

export const stepsStyles: BoxStyles = {
  inner: { margin: theme.spacing([0, 0, 1]) }
}
